<template>
  <div class="login">
    <section class="pt-120 pb-120 position-relative overflow-hidden">
      <div class="contact-left-el">
        <img src="@/assets/images/elements/contact-left-el.png" alt="image" />
      </div>
      <div class="contact-right-el">
        <img src="@/assets/images/elements/contact-right-el.png" alt="image" />
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-header text-center has--bg">
              <div class="header-image">
                <img src="@/assets/images/elements/header-el.png" alt="image" />
              </div>
              <h2 class="section-title">Funny-Play</h2>
              <p>Ingresa, sé el mejor y gana premios semanalmente !</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <div class="contact-form-wrapper">
              <span v-if="formSuccess" class="alert alert-success email-success">
                Número correcto
              </span>
              <span v-if="formError.status" id="error-msg" class="alert alert-danger">{{ formError.msg }}</span>
              <form class="login-form" id="login_form" v-on:submit.prevent="onSubmit">
                <div class="form-group">
                  <label>Número Telefónico</label>
                  <input
                    type="number"
                    v-model="phoneNumber"
                    id="phoneNumber"
                    placeholder="Número Telefónico"
                    class="form-control style--two"
                    required
                  />
                </div>
                <div class="form-group">
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customControlAutosizing"
                      required
                    />
                    <label
                      class="custom-control-label"
                      for="customControlAutosizing"
                      ><a href="www.google.cl">
                        Acepto los términos y condiciones
                      </a>
                    </label>
                  </div>
                </div>
                <div>
                  <button type="submit" class="submit-btn">Aceptar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import axios from "axios";
import router from "../router/index";

export default {
  data() {
    return {
      phoneNumber: null,
      formSuccess: false,
      formError: {
        status: false,
        msg: ""
      }
    }
  },
  methods: {
    onSubmit(e) {
      axios.post("https://reqres.in/api/login",{
        email: "eve.holt@reqres.in",
        password: "cityslicka"
      })
      .then(() => {
        this.formSuccess = true;
        setTimeout(function (){
          router.push("/games");
        }, 1500);
      })
      .catch((error) => {
        this.formError.status = true;
        this.formError.msg = (error.response) ? "Error Controlado" : "Error Inesperado";
      });

      e.preventDefault();
    }
  }
};
</script>
    
<style lang="css">
</style> 